import { Link } from "gatsby";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { FaEnvelope } from "react-icons/fa";

const Newsletter = () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // conditional validation - when this is active, display checkbox
  const agree = watch("phone");

  const onSubmit = (data) => {
    const bodyPayload = {
      person: {
        family_name: data.family_name || "",
        given_name: data.given_name || "",
        postal_addresses: [{ postal_code: data.zip || "" }],
        email_addresses: [{ address: data.email, status: "subscribed" }],
      },
      add_tags: ["Actor for Democracy"],
      triggers: {
        autoresponse: {
          enabled: true,
        },
      },
    };
    if (data.phone) {
      bodyPayload.person.phone_numbers = [
        { number: data.phone, status: "subscribed" },
      ];
    }
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyPayload),
    };

    fetch(
      `https://actionnetwork.org/api/v2/forms/${process.env.GATSBY_AN_ENDPOINT}/submissions/`,
      payload
    )
      .then((res) => {
        console.log({ res });
        return res;
        // return res.json()
      })
      .then((data) => {
        setSubmitted(true);
        reset();
        console.log("response arrived", { data });
      });
  };
  return (
    <section className="newsletter-area pt-5 pb-5" id="newsletter">
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-6 mx-auto text-center">
            <div className="section-heading footer-heading">
              <div className="section-icon">
                <FaEnvelope />
              </div>
              <h2 className="section__title text__white">
                Join the movement <br />
              </h2>
              <span>... don't worry, we hate spam just as much as you</span>
            </div>
            <div className="newsletter-form">
              <div className="form-shared">
                {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
                {!submitted ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address *"
                            {...register("email", {
                              required: "Please provide an email address",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errors.email && (
                            <p className="validation-error">
                              {errors.email.message}
                            </p>
                          )}
                          <input
                            type="text"
                            name="given_name"
                            className="form-control"
                            placeholder="First name"
                            {...register("given_name")}
                          />

                          <input
                            type="text"
                            name="family_name"
                            className="form-control"
                            placeholder="Last name"
                            {...register("family_name")}
                          />
                          <input
                            type="text"
                            name="zip"
                            className="form-control"
                            placeholder="ZIP code"
                            {...register("zip")}
                          />
                          <Controller
                            name="phone"
                            control={control}
                            rules={{
                              validate: (value) =>
                                value &&
                                typeof value === "string" &&
                                isValidPhoneNumber(value),
                            }}
                            render={({ field: { onChange, value } }) => (
                              <div className="phone-wrapper">
                                <div className="phone-intern">+1 </div>
                                <Input
                                  value={value}
                                  onChange={onChange}
                                  country="US"
                                  className="form-control phone-input"
                                  id="phone-input"
                                  placeholder="555 123 4567"
                                />
                              </div>
                            )}
                          />
                          {errors["phone"] && (
                            <p className="validation-error">Invalid Phone</p>
                          )}
                          {agree && (
                            <div className="form-agree-part">
                              <label
                                htmlFor="agree"
                                className="form-agree-part__label"
                              >
                                <input
                                  type="checkbox"
                                  name="agree"
                                  id="agree"
                                  className="form-control form-agree-part__cb"
                                  {...register("agree", {
                                    required: "Please check this box.",
                                  })}
                                />{" "}
                                Receive mobile alerts from Action for Democracy.
                                Recurring messages. Msg &amp; data rates may
                                apply. Text STOP to 668366 to stop receiving
                                messages. Text HELP to 668366 for more
                                information.{" "}
                                <Link to="/terms">
                                  Terms &amp; Conditions &amp; Privacy Policy
                                </Link>
                              </label>
                              {errors.agree && (
                                <p className="validation-error">
                                  {errors.agree.message}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 text-center">
                        <button className="theme-btn submit__btn" type="submit">
                          subscribe
                        </button>
                        <p className="form-agree-part__label">
                          U.S. and U.S. territories only
                        </p>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p className="text-center">
                    You have succesfully subscribed and will receive a
                    confirmation email soon. <br />
                    <button
                      className="mt-5 theme-btn"
                      onClick={(e) => setSubmitted(false)}
                    >
                      Sign up with a different email
                    </button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
