import React from "react";
import hlwImage1 from "../images/illu_4_joseph-chan-hsQhsVb6l1U-unsplash.jpg";
import hlwImage2 from "../images/illu_2_parker-johnson-v0OWc_skg0g-unsplash.jpg";
import hlwImage3 from "../images/illu_3_little-plant-WD4_opOttT0-unsplash.jpg";
import { FacebookShareButton } from "react-share";
import { seo, social } from "../modules/settings";
const HiwItem = ({ link, image, title, linkText, iterator }) => (
  <div className="col">
    <div className="hiw-item">
      <div className={`hiw-item-img-box hiw-bg${iterator}`}>
        <figure>
          <img src={image} height={492} alt="" />
          <h3 className="hiw-title">{title}</h3>
          <div className="hiw-btn-box">
            {iterator === 3 ? (
              <FacebookShareButton url={seo.ogUrl}>
                {" "}
                <p className="theme-btn theme-btn-2">{linkText}</p>
              </FacebookShareButton>
            ) : (
              <a href={link} className="theme-btn theme-btn-2">
                {linkText}
              </a>
            )}
          </div>
        </figure>
      </div>
    </div>
  </div>
);

const HiwArea = () => {
  return (
    <section className="hiw-area" id="joinus">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 section-heading">
            <h2 className="section__title hiw-title">
              Join our movement to defend democracy in Hungary
            </h2>
            <p className="section__meta text-center">
              Here is how you can help
            </p>
          </div>
        </div>
        <div className="row">
          <HiwItem
            link={
              "https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
            }
            image={hlwImage1}
            title={"Support the movement"}
            linkText={"donate now"}
            iterator={2}
          />
          <HiwItem
            link={"/donate"}
            image={hlwImage3}
            title={"Raise your voice"}
            linkText={"share on facebook"}
            iterator={3}
          />
          <HiwItem
            link={"https://magyartavasz.hu/hogyan-regisztralj/"}
            image={hlwImage2}
            title={"Hungarian abroad? Register to vote!"}
            linkText={"voting info"}
            iterator={1}
          />
        </div>
      </div>
    </section>
  );
};

export default HiwArea;
